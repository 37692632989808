import { Button } from 'antd';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';
import useAppRouting from 'hooks/useAppRouting';

function ThirdSection() {
  const { goToPage } = useAppRouting();

  return (
    <section className="pt-60 pb-60">
      <div className="container-lg">
        <p className="landing-subtitle text-center text-uppercase">
          <FormattedMessage id="landing.weAreHereToHelp" />
        </p>
        <h2 className="landing-title text-center">
          <FormattedMessage id="landing.weAreHereToHelp.title" />
        </h2>
        <div className="landing-simple-text text-center mb-60">
          <p>
            <FormattedMessage id="landing.weAreHereToHelp.text_1" />
          </p>
          <p>
            <FormattedMessage id="landing.weAreHereToHelp.text_2" />
          </p>
        </div>
        <div className="landing-section__items landing-section__items--nowrap">
          <div className="landing-section__item">
            <div className="mb-40">
              <Image src="/images/landing/care1.svg" width={100} height={100} alt="" />
            </div>
            <p className="text-subheader text-center mb-24">
              <FormattedMessage id="landing.weAreHereToHelp.item_1" />
            </p>
            <Button
              className="w-100 mt-auto"
              type={'secondary' as any}
              size="large"
              onClick={goToPage(ROUTES.REGISTRATION.PATH)}
            >
              <FormattedMessage id="landing.weAreHereToHelp.seeSpecialist" />
            </Button>
          </div>
          <div className="landing-section__item">
            <div className="mb-40">
              <Image src="/images/landing/care2.svg" width={100} height={100} alt="" />
            </div>
            <p className="text-subheader text-center mb-24">
              <FormattedMessage id="landing.weAreHereToHelp.item_2" />
            </p>
            <Button
              className="w-100 mt-auto"
              type={'secondary' as any}
              size="large"
              onClick={goToPage(ROUTES.REGISTRATION.PATH)}
            >
              <FormattedMessage id="landing.weAreHereToHelp.seeProviders" />
            </Button>
          </div>
          <div className="landing-section__item">
            <div className="mb-40">
              <Image src="/images/landing/care3.svg" width={100} height={100} alt="" />
            </div>
            <p className="text-subheader text-center mb-24">
              <FormattedMessage id="landing.weAreHereToHelp.item_3" />
            </p>
            <Button
              className="w-100 mt-auto"
              type={'secondary' as any}
              size="large"
              onClick={goToPage(ROUTES.REGISTRATION.PATH)}
            >
              <FormattedMessage id="landing.weAreHereToHelp.seeAvailability" />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThirdSection;
