import type { GetServerSidePropsContext } from 'next';
import { mergeDeepRight } from 'ramda';

// The result of the middleware can be any
type Middleware = (ctx: GetServerSidePropsContext) => Promise<any>;

const applyMiddlewares =
  (...funcs: Middleware[]) =>
  async (ctx: GetServerSidePropsContext) => {
    let result = {};

    // eslint-disable-next-line no-restricted-syntax -- intentional
    for (const fn of funcs) {
      // eslint-disable-next-line no-await-in-loop -- intentional
      const props = await fn(ctx);

      result = mergeDeepRight(result, props);

      if (props.redirect) {
        return result;
      }
    }

    return result;
  };

export default applyMiddlewares;
