import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

function TopSection() {
  return (
    <section className="landing-top-section">
      <div className="container-lg">
        <div className="landing-top-section__content">
          <p className="landing-subtitle mb-24 text-uppercase">
            <FormattedMessage id="landing.letsGo" />
          </p>
          <h2 className="landing-title">
            <FormattedMessage
              id="landing.weHelpToFindTheBestDoctorNearYou"
              values={{
                bestDoctor: (
                  <span className="landing-title__deco-text">
                    <FormattedMessage id="landing.bestDoctor" />
                  </span>
                ),
              }}
            />
          </h2>
          <p className="landing-simple-text">
            <FormattedMessage id="landing.simpleText" />
          </p>
        </div>
        <div className="landing-top-section__image">
          <svg width="100%" height="100%">
            <defs>
              <linearGradient id="test">
                <stop offset="0%" stopColor="#fff" />
                <stop offset="100%" stopColor="#dbe6ff" />
              </linearGradient>
            </defs>
            <circle cx="50%" cy="50%" r="49.5%" fill="none" stroke="url(#test)" strokeWidth="5" />
          </svg>
          <div className="landing-top-section__image-inner">
            <div className="landing-top-section__image-inner-pic">
              <Image src="/images/landing/landing1.webp" width={496} height={685} alt="" />
            </div>
          </div>
          <div className="landing-top-section__icon-doctor">
            <Image src="/images/landing/top_section_doc.svg" width={60} height={60} alt="" />
          </div>
          <div className="landing-top-section__icon-arrow">
            <Image src="/images/landing/top_section_arrow.svg" width={60} height={60} alt="" />
          </div>
          <div className="landing-top-section__icon-time">
            <Image src="/images/landing/top_section_time.svg" width={60} height={60} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopSection;
