import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

function FourthSection() {
  return (
    <section className="pt-60 pb-60 bg-secondary">
      <div className="container-lg">
        <p className="landing-subtitle text-uppercase">
          <FormattedMessage id="landing.howItWorks" />
        </p>
        <Row gutter={24}>
          <Col span={24} lg={8}>
            <h2 className="landing-title mb-0">
              <FormattedMessage id="landing.howItWorks.title" />
            </h2>
          </Col>
          <Col span={24} lg={16}>
            <div className="landing-appoint-plan-list">
              <div>
                <div className="d-flex mb-24">
                  <div className="landing-squad-icon mr-16">
                    <i className="icon icon-location fs-24" />
                  </div>
                  <p className="text-subheader fw-600">
                    <FormattedMessage id="landing.howItWorks.item_1.title" values={{ breakline: <br /> }} />
                  </p>
                </div>
                <p className="text-subheader in-black">
                  <FormattedMessage id="landing.howItWorks.item_1.text" />
                </p>
              </div>
              <div>
                <div className="d-flex mb-24">
                  <div className="landing-squad-icon mr-16">
                    <i className="icon icon-calendar fs-24" />
                  </div>
                  <p className="text-subheader fw-600">
                    <FormattedMessage id="landing.howItWorks.item_2.title" values={{ breakline: <br /> }} />
                  </p>
                </div>
                <p className="text-subheader in-black">
                  <FormattedMessage id="landing.howItWorks.item_2.text" />
                </p>
              </div>
              <div>
                <div className="d-flex mb-24">
                  <div className="landing-squad-icon mr-16">
                    <i className="icon icon-payment fs-24" />
                  </div>
                  <p className="text-subheader fw-600">
                    <FormattedMessage id="landing.howItWorks.item_3.title" values={{ breakline: <br /> }} />
                  </p>
                </div>
                <p className="text-subheader in-black">
                  <FormattedMessage id="landing.howItWorks.item_3.text" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default FourthSection;
