import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';
import useAppRouting from 'hooks/useAppRouting';

function BottomSection() {
  const { goToPage } = useAppRouting();

  return (
    <section className="pt-60 pb-60">
      <div className="container-lg">
        <div className="landing-bottom">
          <div>
            <p className="landing-subtitle text-uppercasee">
              <FormattedMessage id="landing.letsGoInTouch" />
            </p>
            <p className="landing-bottom__title">
              <FormattedMessage id="landing.doYouHaveAnyQuestions" />
            </p>
          </div>
          <Button
            className="min-w-200"
            type={'secondary' as any}
            size="large"
            onClick={goToPage(ROUTES.CONTACT_US.PATH)}
          >
            <FormattedMessage id="shared.contactUs" />
          </Button>
        </div>
      </div>
    </section>
  );
}

export default BottomSection;
