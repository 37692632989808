import type { GetServerSidePropsContext } from 'next';

import loadIntlMessages from 'utils/loadIntlMessages';

const withIntlLocales = (namespace?: string | string[]) => async (ctx: GetServerSidePropsContext) => {
  const { locale } = ctx;

  return {
    props: {
      intlMessages: loadIntlMessages(locale, namespace),
    },
  };
};

export default withIntlLocales;
