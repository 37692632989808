import { Row, Col } from 'antd';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

function SecondSection() {
  return (
    <section className="pt-60 pb-60 bg-secondary">
      <div className="container-lg position-relative">
        <div className="landing-second-section__image-wrap">
          <div className="landing-squad-icon landing-second-section__image-top-icon">
            <i className="icon icon-doctor fs-24" />
          </div>
          <div className="landing-analytics">
            <div className="d-flex align-items-center mb-8">
              <div className="landing-squad-icon mr-16">
                <i className="icon icon-analytics" />
              </div>
              <p className="fw-600">100%</p>
            </div>
            <p className="in-black text-body">
              <FormattedMessage id="landing.ourPatientsAreSatisfiedWithTheFacilitiesWeHave" />
            </p>
          </div>
          <div className="landing-second-section__image">
            <Image src="/images/landing/landing2.webp" width={606} height={583} alt="" />
          </div>
          <div className="landing-call-center">
            <div className="landing-squad-icon landing-squad-icon--white mr-12">
              <i className="icon icon-phone-on fs-18" />
            </div>
            <p>
              <FormattedMessage id="landing.callCenter" values={{ hours: '24/7' }} />
            </p>
          </div>
        </div>
        <Row gutter={24} justify="end">
          <Col span={24} lg={12} xl={10}>
            <p className="landing-subtitle text-uppercase">
              <FormattedMessage id="landing.ourFacilities" />
            </p>
            <h2 className="landing-title">
              <FormattedMessage id="landing.ourFacilities.title" />
            </h2>
            <p className="landing-simple-text mb-24">
              <FormattedMessage id="landing.ourFacilities.text" />
            </p>
            <div className="landing-facility">
              <div className="landing-facility__icon">
                <i className="icon icon-checked fs-14" />
              </div>
              <p className="text-subheader in-black">
                <FormattedMessage id="landing.ourFacilities.item_1" />
              </p>
            </div>
            <div className="landing-facility">
              <div className="landing-facility__icon">
                <i className="icon icon-checked fs-14" />
              </div>
              <p className="text-subheader in-black">
                <FormattedMessage id="landing.ourFacilities.item_2" />
              </p>
            </div>
            <div className="landing-facility">
              <div className="landing-facility__icon">
                <i className="icon icon-checked fs-14" />
              </div>
              <p className="text-subheader in-black">
                <FormattedMessage id="landing.ourFacilities.item_3" />
              </p>
            </div>
            <div className="landing-facility">
              <div className="landing-facility__icon">
                <i className="icon icon-checked fs-14" />
              </div>
              <p className="text-subheader in-black">
                <FormattedMessage id="landing.ourFacilities.item_4" />
              </p>
            </div>
            <div className="landing-facility">
              <div className="landing-facility__icon">
                <i className="icon icon-checked fs-14" />
              </div>
              <p className="text-subheader in-black">
                <FormattedMessage id="landing.ourFacilities.item_5" />
              </p>
            </div>
            <div className="landing-facility">
              <div className="landing-facility__icon">
                <i className="icon icon-checked fs-14" />
              </div>
              <p className="text-subheader in-black">
                <FormattedMessage id="landing.ourFacilities.item_6" />
              </p>
            </div>
            <div className="landing-facility">
              <div className="landing-facility__icon">
                <i className="icon icon-checked fs-14" />
              </div>
              <p className="text-subheader in-black">
                <FormattedMessage id="landing.ourFacilities.item_7" />
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default SecondSection;
