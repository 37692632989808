import StaticLayout from 'views/layouts/StaticLayout';

import BottomSection from './BottomSection';
// import FirstSection from './FirstSection';
import FourthSection from './FourthSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import TopSection from './TopSection';

function PageHome() {
  return (
    <>
      <TopSection />
      {/* TODO: Show "Our doctors" section (needs endpoint) */}
      {/* <FirstSection /> */}
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <BottomSection />
    </>
  );
}

PageHome.getLayout = (page: React.ReactNode) => <StaticLayout>{page}</StaticLayout>;
PageHome.title = { id: 'landing.title' };

export default PageHome;
